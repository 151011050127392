//SPA Version
import Vue from 'vue';
import App from './App.vue';

//OpenIdConnect (Authentication) & axios authentication
import OidcAuthenticator from './plugins/oidcAuthenticator';

//Router (navigation)
import router from './router';

//Breadcrumbs (list of parent-components)
import Breadcrumbs from 'vue-2-breadcrumbs';

//Axios (api communication)
import './axios';

//Eventbus (for communication between components)
import './eventBus';

//Vuex (Runtime data storage)
import { store } from './store';

//vue-i18n (translations)
import i18n from './plugins/i18n';

//vue-instant (autocomplete text)
import 'vue-instant/dist/vue-instant.css';
import VueInstant from 'vue-instant';

//Meta (header-info, meta, title)
import Meta from 'vue-meta';

//Validation
import VeeValidate from 'vee-validate';

//Vue skycons by darksky (weather-icons)
import VueSkycons from 'vue-skycons';

//Bootstrap css (layout classes / js)
//Import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

//Import switch (true / false)
import ToggleButton from 'vue-js-toggle-button';

//v-toaster (simple toaster, to display messages and alike)
import 'v-toaster/dist/v-toaster.css';
import Toaster from 'v-toaster';

//vue-snotify (toaster with more options)
import Snotify, { SnotifyPosition } from 'vue-snotify';
import 'vue-snotify/styles/material.css';

//Tooltips
import VTooltip from 'v-tooltip';

//Maps
//leaflet for maps
// import { Icon }  from 'leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';

//Kendo Start ------------->
//grid, charts & more
import '@progress/kendo-theme-default/dist/all.css';

//Needed for kendo charts
import jquery from 'jquery'

//import '@progress/kendo-ui'; //import all javascript
import '@progress/kendo-ui/js/kendo.grid.js'
import '@progress/kendo-ui/js/kendo.filtercell.js'
import '@progress/kendo-ui/js/kendo.dataviz.chart.js'

import { GridInstaller } from '@progress/kendo-grid-vue-wrapper';
import { DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper';
import { ChartInstaller } from '@progress/kendo-charts-vue-wrapper';

import { Grid } from '@progress/kendo-vue-grid';

//<------------- Kendo End

//Default "box" around a view or component
import Portlet from '@/components/Base/Portlet.vue';

//A spinning wheel to indicate the loading
import LoadingPlaceholder from '@/components/Base/LoadingPlaceholder.vue';

//Defaul style
import '@/assets/metronic/style.bundle.css'; /* styles for the theme */
import '@/assets/metronic/vendors.bundle.css'; /* Icons */
import '@/assets/default.css'; /* own css */
import '@/assets/table.css';
import '@/assets/kendogrid.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBell,
  faEnvelope,
  faMobile,
  faBellSlash,
  faComment,
  faEdit,
  faCaretUp,
  faHdd,
  faExclamationTriangle,
  faHistory,
  faBullhorn,
  faThermometerQuarter,
  faVideoSlash,
  faChartArea,
  faFolder,
  faTabletAlt,
  faSearch,
  faQuestion,
  faTv,
  faClone,
  faFilm,
  faCut,
  faCogs,
  faCog,
  faTasks,
  faImage,
  faMusic,
  faFont,
  faTimes,
  faTrash,
  faPlay,
  faPause,
  faDownload,
  faCheck,
  faCaretLeft,
  faPlus,
  faUpload,
  faEye,
  faInfoCircle,
  faSyncAlt,
  faClock,
  faBug,
  faFilter,
  faNetworkWired,
  faMemory,
  faMicrochip,
  faWifi,
  faServer,
  faChargingStation,
  faRunning,
  faDatabase,
  faMapMarker,
  faLock,
  faStopwatch,
  faCamera,
  faVideo,
  faThermometerHalf,
  faListUl,
  faBandAid,
  faSun,
  faStar,
  faUserFriends,
  faFlag,
  faCouch,
  faHiking,
  faFlagCheckered,
  faHourglassHalf,
  faChartBar,
  faSave,
  faPlusCircle,
  faCaretDown,
  faLink,
  faCompressArrowsAlt,
  faBox,
  faStream,
  faSearchLocation,
  faWrench,
  faImages,
  faFileAlt,
  faCheckCircle,
  faFile,
  faCopy,
  faMinus,
  faLockOpen,
  faStop,
  faArrowsAlt,
  faMapMarkerAlt,
  faShieldAlt,
  faTerminal,
  faCircle,
  faStepForward,
  faSignOutAlt,
  faDirections,
  faCodeBranch,
  faDrawPolygon,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faExpandArrowsAlt,
  faBan,
  faPlug,
  faPowerOff,
  faQuestionCircle,
  faExclamationCircle,
  faTachometerAlt,
  faUser,
  faUserTie,
  faLongArrowAltRight,
  faTicketAlt,
  faMapMarkedAlt,
  faRedoAlt,
  faClipboardList,
  faEnvelopeOpenText,
  faExternalLinkSquareAlt,
  faUsersCog,
  faAddressBook,
  faBiohazard,
  faFileExport,
  faReceipt,
  faClipboardCheck,
  faDoorOpen,
  faDoorClosed,
  faPhotoVideo,
  faVolumeMute,
  faVolumeUp,
  faGlobeEurope,
  faSkiing,
  faRoute,
  faFireAlt,
  faFan,
  faHotel,
  faPrint,
  faCalendarAlt,
  faSnowflake,
  faTools,
  faFileContract,
  faExternalLinkAlt,
  faSwatchbook,
  faTruck,
  faScroll,
  faKey,
  faEnvelopeOpen,
  faPen,
  faPeopleCarry,
  faUniversity,
  faTable,
  faCalendarPlus,
  faHeadset,
  faMountain,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faIdCardAlt,
  faAddressCard,
  faFileSignature,
  faCode
} from "@fortawesome/free-solid-svg-icons";

import { far } from '@fortawesome/free-regular-svg-icons'
import { faFontAwesome, faJira, faConfluence } from '@fortawesome/free-brands-svg-icons'

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";

library.add(
  faBell,
  faEnvelope,
  faMobile,
  faBellSlash,
  faComment,
  faEdit,
  faCaretUp,
  faHdd,
  faExclamationTriangle,
  faHistory,
  faBullhorn,
  faThermometerQuarter,
  faVideoSlash,
  faChartArea,
  faFolder,
  faTabletAlt,
  faSearch,
  faQuestion,
  faTv,
  faClone,
  faFilm,
  faCut,
  faCogs,
  faCog,
  faTasks,
  faImage,
  faMusic,
  faFont,
  faTimes,
  faTrash,
  faPlay,
  faPause,
  faDownload,
  faCheck,
  faCaretLeft,
  faPlus,
  faUpload,
  faEye,
  faInfoCircle,
  faSyncAlt,
  faClock,
  faBug,
  faFilter,
  faNetworkWired,
  faMemory,
  faMicrochip,
  faWifi,
  faServer,
  faChargingStation,
  faRunning,
  faDatabase,
  faLock,
  faMapMarker,
  faStopwatch,
  faCamera,
  far,
  faVideo,
  faThermometerHalf,
  faListUl,
  faBandAid,
  faSun,
  faStar,
  faUserFriends,
  faFlag,
  faCouch,
  faHiking,
  faFlagCheckered,
  faHourglassHalf,
  faChartBar,
  faSave,
  faPlusCircle,
  faCaretDown,
  faLink,
  faCompressArrowsAlt,
  faBox,
  faStream,
  faSearchLocation,
  faWrench,
  faImages,
  faFileAlt,
  faCheckCircle,
  faFile,
  faCopy,
  faMinus,
  faLockOpen,
  faStop,
  faArrowsAlt,
  faMapMarkerAlt,
  faShieldAlt,
  faTerminal,
  faCircle,
  faStepForward,
  faSignOutAlt,
  faDirections,
  faCodeBranch,
  faDrawPolygon,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faExpandArrowsAlt,
  faBan,
  faPlug,
  faPowerOff,
  faQuestionCircle,
  faExclamationCircle,
  faTachometerAlt,
  faUser,
  faUserTie,
  faLongArrowAltRight,
  faEnvelope,
  faTicketAlt,
  faMapMarkedAlt,
  faRedoAlt,
  faClipboardList,
  faEnvelopeOpenText,
  faExternalLinkSquareAlt,
  faUsersCog,
  faAddressBook,
  faBiohazard,
  faFileExport,
  faReceipt,
  faClipboardCheck,
  faDoorOpen,
  faDoorClosed,
  faPhotoVideo,
  faVolumeMute,
  faVolumeUp,
  faGlobeEurope,
  faSkiing,
  faRoute,
  faFireAlt,
  faFan,
  faHotel,
  faPrint,
  faDirections,
  faCalendarAlt,
  faSnowflake,
  faSun,
  faTools,
  faFileContract,
  faExternalLinkAlt,
  faSwatchbook,
  faTruck,
  faScroll,
  faKey,
  faEnvelopeOpen,
  faCodeBranch,
  faJira,
  faFontAwesome,
  faConfluence,
  faPen,
  faPeopleCarry,
  faUniversity,
  faTable,
  faCalendarPlus,
  faHeadset,
  faMountain,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faIdCardAlt,
  faAddressCard,
  faIdCardAlt,
  faFileSignature,
  faCode,
  faBan  
);

Vue.prototype.$spaVersion = "1.3.123";

let authenticator = new OidcAuthenticator({
  authority: 'https://keycloak.production.aws.skiline.cc/auth/realms/MyServices/',
  clientId: 'onsite-suite',
  redirectUri: window.location.origin,
  scope: 'openid offline_access profile email api resource_access',
  /*
  onUserLoaded: (user) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
  }
  */
});
Vue.use(authenticator.getVuePlugin());
Vue.use(Breadcrumbs, {
  template:
    `<div v-if="$breadcrumbs.length" aria-label="breadcrumb" id="m_header_menu" class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light">
      <ol class="breadcrumb">
        <li v-for="(crumb, key) in $breadcrumbs" v-if="crumb.meta.breadcrumb" :key="key" class="breadcrumb-item active" aria-current="page">
          <router-link :to="{ path: getPath(crumb) }">{{ getBreadcrumb(crumb.meta.breadcrumb) }}</router-link>
        </li>
      </ol>
    </div>`
});
Vue.use(VueInstant);
Vue.use(Meta);
Vue.use(VeeValidate);
Vue.use(VueSkycons);
Vue.use(ToggleButton);
Vue.use(Toaster, {timeout: 5000});
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    showProgressBar: false
  }
}
Vue.use(Snotify, options);
Vue.use(VTooltip);

// import 'leaflet.icon.glyph';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
window.$ = jquery
Vue.component('grid', Grid);

Vue.use(GridInstaller);
Vue.use(DataSourceInstaller);
Vue.use(ChartInstaller);
Vue.component('Portlet', Portlet);
Vue.component('LoadingPlaceholder', LoadingPlaceholder);

//Fontawesome by Bernhard
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

//Vue Directive
//can be called on an element with <somelement v-focus/>
Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  },
  // causes a bug where the focus would switch back to the element when changing another parameter
  // update: function (el) {
  //   Vue.nextTick(function() {
  //     el.focus();
  //   })
  // }
})

//Disable production tips
Vue.config.productionTip = false;

//Vue App Init
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)    
}).$mount('#app');
