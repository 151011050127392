import Vue from 'vue';
import Router from 'vue-router';

import installationRoutes from '@/router/installation.js';
import problemRoutes from '@/router/problem.js';
import smsRoutes from '@/router/sms.js';
import videoPostProcessingRoutes from '@/router/videoPostProcessing.js';
import smsManagement from '@/router/smsManagement.js';
import deviceManagement from '@/router/deviceManagement.js';
import errorRoutes from '@/router/error.js';

Vue.use(Router)

const baseRoutes = [
  {
    path: '/',
    component: () => import('./views/Home.vue'),
    meta: {
      breadcrumb: () => `Home`
    }
  },
  {
    path: '/home',
    component: () => import('./views/Home.vue'),
    meta: {
      breadcrumb: () => `Home`
    }
  },
  {
    path: '/test',
    component: () => import('./components/Base/TestComponent.vue'),
    meta: {
      breadcrumb: () => `Test`
    }
  },
  {
    path: '/applicationinfo',
    component: () => import('./views/ApplicationInfo.vue'),
    meta: {
      breadcrumb: () => `Application Info`
    }
  },
  {
    path: '/acknowledgemessages',
    component: () => import('./views/AcknowledgeMessages.vue'),
    meta: {
      breadcrumb: () => `Acknowledge messages`
    }
  },
  {
    path: '/notes',
    component: () => import('@/views/Notes.vue'),
    props: true,
    meta: {
      breadcrumb: `Notes`
    }
  },
  {
    path: '/support-requests',
    component: () => import('@/views/SupportRequestsView.vue'),
    props: true,
    meta: {
      breadcrumb: `Freshdesk`
    }
  },
  {
    path: '/changelogs',
    component: () => import('@/views/ChangelogsView.vue'),
    props: true,
    meta: {
      breadcrumb: `Changelogs`
    }
  },
  {
    path: '/resorts',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Resorts`
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/Resort/ResortsView.vue'),
      },
      {
        path: 'add',
        props: false,
        component: () => import('./components/Resort/AddResort.vue'),
      },
      {
        path: 'id/:id',
        props: true,
        component: () => import('./views/Resort/ResortsView.vue')
      },
      {
        path: 'name/:name',
        props: true,
        component: () => import('./views/Resort/ResortsView.vue')
      }
    ]
  },
  {
    path: '/customer-notification',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Customer Notification`
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/CustomerNotification/CustomerNotificationView.vue'),
      }
    ]
  },
  {
    path: '/cloudstorage',
    component: () => import('./views/CloudStorage.vue'),
    props: true,
    meta: {
      breadcrumb: () => `Cloud Storage`
    },
    children: [
      {
        path: ':path',
        props: true,
        component: () => import('./views/CloudStorage.vue'),
        meta: {
          breadcrumb: routeParams => routeParams.path
        }
      }
    ]
  },
  {
    path: '/applicationsettings',
    component: () => import('./views/ApplicationSettings.vue'),
    meta: {
      breadcrumb:() => 'Application settings'
    }
  },
  {
    path: "/home-office-report",
    props: true,
    component: () => import("./views/HomeOfficeReportView.vue"),
    meta: {
      breadcrumb: "Home Office Report",
    },
  },
  {
    path: "/vacation-report",
    props: true,
    component: () => import("./views/VacationReportView.vue"),
    meta: {
      breadcrumb: "Vacation Report",
    },
  },
  {
    path: "/time-compensation-report",
    props: true,
    component: () => import("./views/TimeCompensationReportView.vue"),
    meta: {
      breadcrumb: "Time Compensation Report",
    },
  },
  {
    path: "/customer-worklog-overview",
    props: true,
    component: () => import("./views/CustomerWorklogOverviewView.vue"),
    meta: {
      breadcrumb: "Customer Worklog Overview",
    },
  },
  {
    path: '/tracing-statistics',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Statistics Report`
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/ContactTracingStatisticView.vue'),
      }
    ]
  },
  {
    path: '/photopoint-media-review',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Photopoint`
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/MediaReview/PhotopointMediaReviewView.vue'),
      }
    ]
  },
  {
    path: '/video-media-review',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Video`
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/MediaReview/VideoMediaReviewView.vue'),
      }
    ]
  },
  {
    path: '/photo-media-review',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Photo`
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/MediaReview/PhotoMediaReviewView.vue'),
      }
    ]
  },
  {
    path: '/media-review-report',
    component: () => import('@/components/Base/ParentComponent.vue'),
    meta: {
      breadcrumb: () => `Report`
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/MediaReview/MediaReviewReportView.vue'),
      }
    ]
  },
  {
    path: '/software-component-types',
    component: () => import('./views/SoftwareComponent/SoftwareComponentTypeView.vue'),
    meta: {
      breadcrumb: () => 'Software Component Types'
    }
  },
  {
    path: '/software-component-types/:componentTypeId',
    props: true,
    component: () => import('./views/SoftwareComponent/SoftwareComponentCommandsOverview.vue'),
    meta: {
      breadcrumb: 'Software Component Commands'
    }
  },
  {
    path: '/on-duty-calendar',
    props: true,
    component: () => import('./views/OnCallDutyCalendarView.vue'),
    meta: {
      breadcrumb: 'On Call Duty'
    }
  },
  {
    path: '/event-calendar',
    props: true,
    component: () => import('./views/EventCalendarView.vue'),
    meta: {
      breadcrumb: 'Event Calendar'
    }
  },
  {
    path: '/delivery-calendar',
    props: true,
    component: () => import('./views/DeliveryCalendarView.vue'),
    meta: {
      breadcrumb: 'Delivery Calendar'
    }
  },
  {
    path: '/rollout-calendar',
    props: true,
    component: () => import('./views/RolloutCalendarView.vue'),
    meta: {
      breadcrumb: 'Rollout Calendar'
    }
  },
  {
    path: '/resource-project-calendar',
    props: true,
    component: () => import('./views/ResourceProjectCalendarView.vue'),
    meta: {
      breadcrumb: 'Resource & Project Calendar'
    },
    children: [
      {
        path: 'add/:issue',
        props: true,
        component: () => import('./views/ResourceProjectCalendarView.vue'),
      },
    ]
  },
  {
    path: '/software-components',
    props: true,
    component: () => import('./views/SoftwareComponent/SoftwareComponentsView.vue'),
    meta: {
      breadcrumb: 'Software Components'
    },
    children: [
      {
        path: 's/:url',
        props: true,
        component: () => import('./views/SoftwareComponent/SoftwareComponentsView.vue'),
      },
      {
        path: 'n/:name',
        props: true,
        component: () => import('./views/SoftwareComponent/SoftwareComponentsView.vue'),
      }
    ]
  },
  {
    path: '/peak',
    props: true,
    component: () => import('./views/SoftwareComponent/PeakLoginDataView.vue'),
    meta: {
      breadcrumb: 'Peaksolution'
    },
    children: [
      {
        path: 'a/:abb',
        props: true,
        component: () => import('./views/SoftwareComponent/PeakLoginDataView.vue'),
      },
      {
        path: 'n/:name',
        props: true,
        component: () => import('./views/SoftwareComponent/PeakLoginDataView.vue'),
      }
    ]
  },
  {
    path: '/login-data',
    props: true,
    component: () => import('./views/SoftwareComponent/FullLoginDataView.vue'),
    meta: {
      breadcrumb: 'Login Data'
    }
  },
  {
    path: '/licence-report',
    props: true,
    component: () => import('./views/SoftwareComponent/LicenceReportView.vue'),
    meta: {
      breadcrumb: 'Licence Report'
    }
  },
  {
    path: '/inbox',
    props: true,
    component: () => import('./views/InboxView.vue'),
    meta: {
      breadcrumb: 'Inbox'
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/InboxView.vue'),
      },
      {
        path: ':noteId',
        props: true,
        component: () => import('./views/InboxView.vue'),
      }
    ]
  },
  {
    path: '/outbox',
    props: true,
    component: () => import('./views/OutboxView.vue'),
    meta: {
      breadcrumb: 'Outbox'
    }    
  },
  {
    path: '/terminal-screenshots',
    props: true,
    component: () => import('./views/TerminalScreenshotView.vue'),
    meta: {
      breadcrumb: 'Screenshots'
    }
  },
  {
    path: '/bulk-commands',
    props: true,
    component: () => import('./views/BulkCommandsView.vue'),
    meta: {
      breadcrumb: 'Bulk Commands'
    }
  },
  {
    path: '/quick-support-commands',
    component: () => import('@/views/QucikSupportCommandsView.vue'),
    meta: {
      breadcrumb: () => `Quick Support Commands`
    }
  },
  {
    path: '/login-data-audit',
    props: true,
    component: () => import('./views/LoginDataAuditView.vue'),
    meta: {
      breadcrumb: 'Login Data Audit'
    }
  },
  {
    path: '/command-audit',
    props: true,
    component: () => import('./views/CommandAuditView.vue'),
    meta: {
      breadcrumb: 'Command Audit'
    }
  },
  {
    path: '/customer-worklog-report',
    props: true,
    component: () => import('./views/CustomerWorklogReportView.vue'),
    meta: {
      breadcrumb: 'Customer Worklog Report'
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/CustomerWorklogReportView.vue'),
      },
      {
        path: ':sMasterComponent/:sComponent/:sStartDate/:sEndDate',
        props: true,
        component: () => import('./views/CustomerWorklogReportView.vue'),
      },
      {
        path: ':sMasterComponent/:sComponent/:sStartDate/:sEndDate/:pdf/:onlyTasks',
        props: true,
        component: () => import('./views/CustomerWorklogReportView.vue'),
      }
    ]
  },
  {
    path: '/employee-worklog-report',
    props: true,
    component: () => import('./views/EmployeeWorklogReportView.vue'),
    meta: {
      breadcrumb: 'Productivity Report'
    },
    children: [
      {
        path: '',
        props: true,
        component: () => import('./views/EmployeeWorklogReportView.vue'),
      },
      {
        path: ':sStartDate/:sEndDate',
        props: true,
        component: () => import('./views/EmployeeWorklogReportView.vue'),
      },
      {
        path: ':sStartDate/:sEndDate/:pdf',
        props: true,
        component: () => import('./views/EmployeeWorklogReportView.vue'),
      }
    ]
  },
  {
    path: '/installation-questions',
    meta: {
      breadcrumb: `Installation Questions`
    },
    component: () => import('@/components/Base/ParentComponent.vue'),
    children: [
      {
        path: '',
        redirect: { path: '/installation-questions/template' },
      },
      {
        path: 'template',
        meta: {
          breadcrumb: `Templates`
        },
        component: () => import('@/components/Base/ParentComponent.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/Installation/InstallationQuestionTemplates.vue'),
          },
          {
            path: ':templateId',
            props: true,
            component: () => import('@/views/Installation/InstallationQuestions.vue'),
            meta: {
              breadcrumb: routeParams => `Template ${routeParams.templateId}`
            }
          }
        ]
      }
    ]
  },
  {
    path: '/customers',
    props: true,
    component: () => import('./views/Contacts/CustomersView.vue'),
    meta: {
      breadcrumb: 'Customers'
    }
  },
  {
    path: '/contact-roles',
    props: true,
    component: () => import('./views/Contacts/ContactRolesView.vue'),
    meta: {
      breadcrumb: 'Contact Roles'
    }
  },
  {
    path: '/contacts',
    props: true,
    component: () => import('./views/Contacts/ContactsView.vue'),
    meta: {
      breadcrumb: 'Contacts'
    }
  },
  {
    path: '/contacts/:phone',
    props: true,
    component: () => import('./views/Contacts/ContactsView.vue'),
    meta: {
      breadcrumb: 'Contacts'
    }
  }
];

const unknownRoute = [
  {
    path: '/*',
    component: () => import('./views/Error.vue')
  }
];

const routes = baseRoutes.concat(installationRoutes, problemRoutes, smsRoutes, videoPostProcessingRoutes, smsManagement, deviceManagement, errorRoutes, unknownRoute);

export default new Router({
  routes,
  mode: 'history',
  // base: process.env.BASE_URL,
})
